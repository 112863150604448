import React from 'react';
import { useGetLoginInfo } from '@multiversx/sdk-dapp/hooks';
import {
  ExtensionLoginButton,
  WebWalletLoginButton,
  LedgerLoginButton,
  WalletConnectLoginButton
} from '@multiversx/sdk-dapp/UI';
import { AuthRedirectWrapper } from 'components';
import { walletConnectV2ProjectId } from 'config';
import { routeNames } from 'routes';
import { useNavigate } from 'react-router-dom';

const UnlockPage = () => {

  const { isLoggedIn } = useGetLoginInfo();

  const navigate = useNavigate();

  React.useEffect(() => {
    if (isLoggedIn) {
      navigate(routeNames.mint, { replace: true });
    }
  }, [isLoggedIn]);

  return (
    <div className='home d-flex flex-fill custom-unlock-button'>
      <div className='m-auto' data-testid='unlockPage'>
        <h3 className='cpc-styled'>CONNECT WALLET</h3>
        <p className='text-center'>
          Connect with your MultiversX Wallet.
          <br />
          Select your login method:
        </p>
        <div className='col-12 d-flex'>
          <ExtensionLoginButton
            className='is-style-cpc-btn-styled button'
            callbackRoute={routeNames.mint}
            loginButtonText={'Extension'}
          >
            <div className='d-flex align-items-center'>
              <span>{'EXTENSION'}</span>
            </div>
          </ExtensionLoginButton>
        </div>
        <div className='col-12 d-flex'>
          <WalletConnectLoginButton
            className='is-style-cpc-btn-styled button'
            callbackRoute={routeNames.mint}
            loginButtonText={'Maiar'}
            {...(walletConnectV2ProjectId
              ? {
                isWalletConnectV2: true
              }
              : {})}
          >
            <div className='d-flex align-items-center'>
              <span>{'xPortal App'}</span>
            </div>
          </WalletConnectLoginButton>
        </div>
        <div className='col-12 d-flex'>
          <WebWalletLoginButton
            className='is-style-cpc-btn-styled button'
            callbackRoute={routeNames.mint}
            loginButtonText={'Web wallet'}
          >
            <div className='d-flex align-items-center'>
              <span>{'WEB WALLET'}</span>
            </div>
          </WebWalletLoginButton>
        </div>
        <div className='col-12 d-flex'>
          <LedgerLoginButton
            loginButtonText={'Ledger'}
            callbackRoute={routeNames.mint}
            className='is-style-cpc-btn-styled button'
          >
            <div className='d-flex align-items-center'>
              <span>{'LEDGER'}</span>
            </div>
          </LedgerLoginButton>
        </div>
      </div>
    </div>
  );
};

export const Unlock = () => (
  <AuthRedirectWrapper>
    <UnlockPage />
  </AuthRedirectWrapper>
);
