import { EnvironmentsEnum } from '@multiversx/sdk-dapp/types';

export const dAppName = 'CitizenNFT Collection Mint | Cyberpunk City';

// Generate your own WalletConnect 2 ProjectId here: https://cloud.walletconnect.com/app
export const walletConnectV2ProjectId = 'bf85786ead08ce86a1140ce9f1dd073b';

export const apiTimeout = 6000;
export const transactionSize = 15;

export const TOKEN_DECIMAL = 18;
export const MINT_PRICE = 1000;
export const PHASE = 1;

export const SFT_CONTRACT_ADDRESS = 'erd1qqqqqqqqqqqqqpgqvaf2392fzty37jh82kjcrw4mdfq2q7mxuslsq6nknh';
export const SFT_CONTRACT_ABI_URL = '/abi/sft-mint-contract.abi.json';
export const SFT_CONTRACT_NAME = 'SFTMint';

export const BOX_SFT_ID = 'CYBERCASE-11ad85';
export const CITIZEN_SFT_ID = 'CITIZENNFT-b4408a';
export const CYBER_TOKEN_ID = 'CYBER-489c1c';

export const ENVIRONMENT = EnvironmentsEnum.mainnet;
export const GATEWAY = 'https://api.elrond.com';


// export const SFT_CONTRACT_ADDRESS = 'erd1qqqqqqqqqqqqqpgqw672ha0m9kgxu3jntuqhfu3al9d3l3685zvs9n0ayk';
// export const SFT_CONTRACT_ABI_URL = '/abi/sft-mint-contract.abi.json';
// export const SFT_CONTRACT_NAME = 'SFTMint';

// export const CITIZEN_SFT_ID = 'CITYNFT-150731';
// export const BOX_SFT_ID = 'CITY-47279e';
// export const CYBER_TOKEN_ID = 'CYBER-b205dd';

// export const ENVIRONMENT = EnvironmentsEnum.devnet;
// export const GATEWAY = 'https://devnet-api.elrond.com';
