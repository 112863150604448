import React, { useState, useEffect } from 'react';
import { BOX_SFT_ID } from 'config';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import './index.scss';

const Card = (props: any) => {

  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className='sft-container'>
      <div className='sft-container-status'>
        <div className='sft-container-status-phase-1 p-1'>
          {props.item?.name}
        </div>
      </div>
      <div className='sft-container-sft-asset'>
        <img src={props.item?.url} alt='sft-image' onClick={() => setIsOpen(!isOpen)}></img>
        {isOpen && (
          <Lightbox
            mainSrc={props.item?.url}
            onCloseRequest={() => setIsOpen(!isOpen)}
            enableZoom={false}
          />
        )}
      </div>
      <div className='sft-container-status-sale-amount p-1'>
        {props.item?.balance}/{props.item?.collection == BOX_SFT_ID ? 3000 : 200} Owned
      </div>
    </div>
  );
};

export default Card;
