import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import Time from 'components/Time';
import { routeNames } from 'routes';
import { useNavigate } from 'react-router-dom';
import { useGetIsLoggedIn, useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import { useGetNetworkConfig, useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import { ApiNetworkProvider } from '@multiversx/sdk-network-providers/out';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import BigNumber from 'bignumber.js/bignumber.js';
import {
  Account,
  TransactionPayload,
  Address,
  SmartContract,
  AbiRegistry,
  BigUIntValue,
  ContractFunction,
  AddressValue,
  SmartContractAbi,
  ResultsParser,
  TokenPayment,
  TypedValue,
  BytesValue,
  ArgSerializer,
  U32Value,
} from '@multiversx/sdk-core/out';
import axios from 'axios';
import Card from 'components/Card';
import sftAbi from 'abi/sft-mint-contract.abi.json';
import LinearProgress, {
  linearProgressClasses
} from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import {
  MINT_PRICE,
  SFT_CONTRACT_ADDRESS,
  SFT_CONTRACT_ABI_URL,
  SFT_CONTRACT_NAME,
  TOKEN_DECIMAL,
  PHASE,
  CYBER_TOKEN_ID,
  CITIZEN_SFT_ID,
  BOX_SFT_ID,
  GATEWAY,
} from 'config';
import { convertWeiToEgld } from 'utils/convert';
import { SECOND_IN_MILLI } from 'utils/const';
import VideoThumbnail from 'assets/img/video-thumbnail.jpg';

import './index.scss';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  marginTop: '3px',
  height: 25,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    borderRadius: 8,
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 8,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'
  }
}));

const Mint = () => {

  const navigate = useNavigate();
  const { address, account } = useGetAccountInfo();
  const isLoggedIn = Boolean(address);
  const { hasPendingTransactions } = useGetPendingTransactions();
  const { network } = useGetNetworkConfig();
  const networkProvider = new ApiNetworkProvider(network.apiAddress);

  const handleLogin = () => {
    navigate(routeNames.unlock, { replace: true });
  };

  // load smart contract abi and parse it to SmartContract object for tx
  const [contractInteractor, setContractInteractor] = useState<any>(undefined);
  useEffect(() => {
    const ABI = sftAbi as any;
    // console.log(ABI);
    (async () => {
      const abiRegistry = AbiRegistry.create(ABI);
      const abi = new SmartContractAbi(abiRegistry, [
        SFT_CONTRACT_NAME
      ]);
      const contract = new SmartContract({
        address: new Address(SFT_CONTRACT_ADDRESS),
        abi: abi
      });
      setContractInteractor(contract);
    })();
  }, []); // [] makes useEffect run once

  const [boxSftDatas, setBoxSftDatas] = useState<any>();
  const [citizenSftDatas, setCitizenSftDatas] = useState<any>();
  const [sftMintPhase, setSftMintPhase] = useState<any>();
  useEffect(() => {

    if (isLoggedIn) {
      axios
        .get(
          `${GATEWAY}/accounts/${address}/nfts?from=0&size=500&collection=${BOX_SFT_ID}`
        )
        .then((res) => {
          if (res.status === 200 && res?.data?.length > 0) {
            setBoxSftDatas(res.data);
          } else {
            setBoxSftDatas([]);
          }
        });

      axios
        .get(
          `${GATEWAY}/accounts/${address}/nfts?from=0&size=500&collection=${CITIZEN_SFT_ID}`
        )
        .then((res) => {
          if (res.status === 200 && res?.data?.length > 0) {
            setCitizenSftDatas(res.data);
          } else {
            setCitizenSftDatas([]);
          }
        });
    }

    (async () => {
      if (!contractInteractor) return;

      let value;
      if (isLoggedIn) {
        const query = contractInteractor.createQuery({
          func: new ContractFunction('getPhaseInfosWithAddress'),
          args: [new AddressValue(new Address(address))]
        });
        const resultsParser = new ResultsParser();
        const response = await networkProvider.queryContract(query);
        const endpointDefinition = contractInteractor.getEndpoint(
          'getPhaseInfosWithAddress'
        );
        const res = resultsParser.parseQueryResponse(
          response,
          endpointDefinition
        );
        value = res.firstValue?.valueOf();
      } else {
        const query = contractInteractor.createQuery({
          func: new ContractFunction('getPhaseInfos')
        });
        const resultsParser = new ResultsParser();
        const response = await networkProvider.queryContract(query);
        const endpointDefinition =
          contractInteractor.getEndpoint('getPhaseInfos');
        const res = resultsParser.parseQueryResponse(
          response,
          endpointDefinition
        );
        value = res.firstValue?.valueOf();
      }

      const phases: any = [];
      value.map((item: any) => {
        const phase = {
          phase_id: item.phase_id.toNumber(),
          minting_price: convertWeiToEgld(item.minting_price.toNumber(), TOKEN_DECIMAL),
          discounted_minting_price: convertWeiToEgld(item.discounted_minting_price.toNumber(), TOKEN_DECIMAL),
          burn_percent: item.burn_percent.toNumber(),
          treasury_percent: item.treasury_percent.toNumber(),
          building_set_mint_count_in_whitelist: item.building_set_mint_count_in_whitelist.toNumber(),
          mint_max_count: item.mint_max_count.toNumber(),
          box_sft_nonce: item.box_sft_nonce.toNumber(),
          start_sft_nonce: item.start_sft_nonce.toNumber(),
          last_sft_nonce: item.last_sft_nonce.toNumber(),
          sft_supply_per_nonce: item.sft_supply_per_nonce.toNumber(),
          sft_total_supply: item.sft_total_supply.toNumber(),
          sft_total_minted_count: item.sft_total_minted_count.toNumber(),
          sft_minted_live: item.sft_minted_live,
          sft_mint_start_timestamp: item.sft_mint_start_timestamp.toNumber() * SECOND_IN_MILLI,
          sft_mint_whitelist: item.sft_mint_whitelist,
          user_sft_mint_whitelist_sets: item.user_sft_mint_whitelist_sets.toNumber(),
          user_sft_minted_count: item.user_sft_minted_count.toNumber()
        };
        phases.push(phase);
      });
      // console.log(phases);
      setSftMintPhase(phases);
    })();
  }, [contractInteractor, hasPendingTransactions]);

  const [value, setValue] = useState(1);

  const handleMax = () => {
    const remainedSupply = sftMintPhase[PHASE - 1]?.sft_total_supply - sftMintPhase[PHASE - 1]?.sft_total_minted_count;
    let mintCount = 0;
    if (isLoggedIn) {
      const maxMintableAbmount = sftMintPhase[PHASE - 1]?.building_set_mint_count_in_whitelist * sftMintPhase[PHASE - 1]?.user_sft_mint_whitelist_sets + sftMintPhase[PHASE - 1]?.mint_max_count;
      mintCount = maxMintableAbmount - sftMintPhase[PHASE - 1]?.user_sft_minted_count;
    } else {
      mintCount = sftMintPhase[PHASE - 1]?.mint_max_count;
    }
    if (mintCount > remainedSupply) {
      mintCount = remainedSupply;
    }
    setValue(mintCount);
  };

  const handleInput = (e: any) => {
    const maxMintableAbmount = sftMintPhase[PHASE - 1]?.building_set_mint_count_in_whitelist * sftMintPhase[PHASE - 1]?.user_sft_mint_whitelist_sets + sftMintPhase[PHASE - 1]?.mint_max_count;
    const maxPurchase = maxMintableAbmount - sftMintPhase[PHASE - 1]?.user_sft_minted_count;
    const remainedSupply = sftMintPhase[PHASE - 1]?.sft_total_supply - sftMintPhase[PHASE - 1]?.sft_total_minted_count;

    let mintCount = 0;
    if (e.target.value > maxPurchase) {
      mintCount = maxPurchase;
    } else {
      mintCount = e.target.value;
    }
    if (mintCount > remainedSupply) {
      mintCount = remainedSupply;
    }
    setValue(mintCount);
  };

  const handleMint = async () => {
    const maxMintableAbmount = sftMintPhase[PHASE - 1]?.building_set_mint_count_in_whitelist * sftMintPhase[PHASE - 1]?.user_sft_mint_whitelist_sets + sftMintPhase[PHASE - 1]?.mint_max_count;
    const maxPurchase = maxMintableAbmount - sftMintPhase[PHASE - 1]?.user_sft_minted_count;
    const remainedSupply = sftMintPhase[PHASE - 1]?.sft_total_supply - sftMintPhase[PHASE - 1]?.sft_total_minted_count;
    if (value > maxPurchase || value > remainedSupply) return;
    let mintPrice = value * sftMintPhase[PHASE - 1]?.minting_price;
    if (sftMintPhase[PHASE - 1]?.sft_mint_whitelist) {
      mintPrice = value * sftMintPhase[PHASE - 1]?.discounted_minting_price;
    }
    const amount = new BigNumber(mintPrice).multipliedBy(
      Math.pow(10, TOKEN_DECIMAL)
    );
    const args: TypedValue[] = [
      BytesValue.fromUTF8(CYBER_TOKEN_ID),
      new BigUIntValue(amount.valueOf()),
      BytesValue.fromUTF8('mint'),
      new U32Value(PHASE),
      new U32Value(value)
    ];

    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = new TransactionPayload(`ESDTTransfer@${argumentsString}`);
    const tx = {
      receiver: SFT_CONTRACT_ADDRESS,
      gasLimit: 60000000,
      value: 0,
      data: data.toString()
    };
    await refreshAccount();

    await sendTransactions({
      transactions: tx,
      transactionsDisplayInfo: {
        processingMessage: 'Processing Mint transaction',
        errorMessage: 'An error has occured during Mint',
        successMessage: 'Mint transaction successful'
      },
      redirectAfterSign: false
    });
  };

  return (
    <div className='container'>
      <Row>
        <Col lg={6} className='container-left'>
          <h1 className='cpc-styled'>CitizenNFT Collection</h1>
          <p>
          A collection of 50 unique, fully customizable and rentable characters for Cyberpunk City; designed and drawn from the ground up with professional, realistic and artistic standards. Every character has 200 duplicates, which brings the total number to 10,000. This phase will contain 3000 characters used inside the metaverse. During phase 1, each character comes with CASE Trio that has 3x chance to reveal a rare item.
          </p>

          <p>
          50% of the $CYBER tokens collected from the minting event will be burned, 50% of the tokens will be staked and the rewards will go to CitizenNFT holders (till Beta release). After the Beta release tokens will be unstaked and the company will keep 30% as a reserve and 20% will go to Play-to-Earn wallet.
          </p>

          <p>⚠️ Minting is possible only with $CYBER.</p>
          <div className='container-left-buttons'>
            <a
              href='https://xexchange.com/swap'
              target='_blank'
              rel='noreferrer'
            >
              <button >
                BUY CYBER
              </button>
            </a>
            <a
              href='https://cyberpunkcity.com'
            >
            <button className='ml-3'>
                GO TO HOMEPAGE
            </button>
            </a>

          </div>
        </Col>
        {sftMintPhase?.length > 0 ? (
          <Col lg={6} className='container-right p-0'>
            <div className='presale-container'>
              <div className='presale-container-status'>
                <div className='presale-container-status-left'>
                  <div className='presale-container-status-phase-1'>
                    CitizenNFT
                  </div>
                  {Date.now() > sftMintPhase[PHASE - 1]?.sft_mint_start_timestamp ? (
                    sftMintPhase[PHASE - 1]?.sft_total_minted_count === sftMintPhase[PHASE - 1]?.sft_total_supply ? (
                      <div className='presale-container-status-phase-2'>
                        PHASE {PHASE} -{' '}
                        <span className='sold-out'>SOLD OUT!</span>
                      </div>
                    ) : (
                      <div className='presale-container-status-phase-2'>
                        PHASE {PHASE} -{' '}
                        <span className='live'>LIVE!</span>
                      </div>
                    )
                  ) : (
                    <div className='presale-container-status-phase-2'>
                      PHASE {PHASE} - STARTS IN:
                    </div>
                  )}
                </div>

                <Time
                  leftTimestamp={sftMintPhase[PHASE - 1]?.sft_mint_start_timestamp}
                />

              </div>
              <div className='presale-container-sft-asset'>
                <video className='videoCPC' muted autoPlay loop>
                  <source src={require('assets/img/citizen-video.mp4')} type='video/mp4' />
                  <img src={VideoThumbnail} alt='thumbnail' />
                </video>
              </div>
              <BorderLinearProgress
                variant='determinate'
                value={
                  sftMintPhase[PHASE - 1]?.sft_total_minted_count / sftMintPhase[PHASE - 1]?.sft_total_supply * 100
                }
              />
              <div className='presale-container-status-sale-amount'>
                {sftMintPhase[PHASE - 1]?.sft_total_minted_count} out of {sftMintPhase[PHASE - 1]?.sft_total_supply} SFT
              </div>
              <div className='presale-container-input'>
                <input
                  type='text'
                  name='SFT amount'
                  placeholder='SFT amount'
                  aria-label='SFT amount'
                  value={value}
                  onChange={handleInput}
                />
                <div className='presale-container-input-amount d-flex align-items-center justify-content-end pr-2'>
                  <div
                    className='presale-container-input-text presale-container-input-text-max'
                    onClick={handleMax}
                  >
                    MAX
                  </div>
                </div>
                <div className='presale-container-input-amount d-flex align-items-center justify-content-end pr-2'>
                  <div className='presale-container-input-text'>
                    You&apos;ll pay
                    <br />
                    {sftMintPhase[PHASE - 1]?.sft_mint_whitelist ? (value * sftMintPhase[PHASE - 1]?.discounted_minting_price).toFixed(0) : (value * sftMintPhase[PHASE - 1]?.minting_price).toFixed(0)} CYBER
                  </div>
                </div>
                {isLoggedIn ? (
                  <button
                    onClick={handleMint}
                    className={
                      Date.now() > sftMintPhase[PHASE - 1]?.sft_mint_start_timestamp &&
                      value >= 1 &&
                      value <= sftMintPhase[PHASE - 1]?.sft_total_supply - sftMintPhase[PHASE - 1]?.sft_total_minted_count && 
                      value <= (sftMintPhase[PHASE - 1]?.building_set_mint_count_in_whitelist * sftMintPhase[PHASE - 1]?.user_sft_mint_whitelist_sets + sftMintPhase[PHASE - 1]?.mint_max_count) - sftMintPhase[PHASE - 1]?.user_sft_minted_count
                      ? 'purchase-active-button'
                    : ''
                    }
                  >
                    MINT
                  </button>
                ) : (
                  <button onClick={handleLogin} className='purchase-active-button'>CONNECT</button>
                )}
              </div>
              <div className='presale-container-text'>1 SFT = {sftMintPhase[PHASE - 1]?.sft_mint_whitelist ? sftMintPhase[PHASE - 1]?.discounted_minting_price : MINT_PRICE} CYBER</div>
            </div>
          </Col>
        ) : (
          <></>
        )}
      </Row>

      {citizenSftDatas?.length > 0 ? (
        <Row>
          <Col lg={12}
            md={12}
            sm={12}
            className='mt-5 mb-3'><h2 className='cpc-styled'>My Citizens</h2>
          </Col>
          {citizenSftDatas?.map((item: any, index: any) => {
            return (
              <Col
                lg={2}
                md={3}
                sm={4}
                xs={6}
                className='mb-5 px-2'
                key={index}
              >
                <Card item={item} />
              </Col>
            );
          })}
        </Row>
      ) : (
        <></>
      )}

      {boxSftDatas?.length > 0 ? (
        <Row>
          <Col lg={12}
            md={12}
            sm={12}
            className='mt-5 mb-3'><h2 className='cpc-styled'>My Cases</h2>
          </Col>
          {boxSftDatas?.map((item: any, index: any) => {
            return (
              <Col
                lg={3}
                md={4}
                sm={6}
                xs={12}
                className='mb-5 px-2'
                key={index}
              >
                <Card item={item} />
              </Col>
            );
          })}
        </Row>
      ) : (
        <></>
      )}

    </div>
  );
};

export default Mint;
