import React, { useEffect, useState } from 'react';
import { useGetIsLoggedIn, useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import { logout } from '@multiversx/sdk-dapp/utils';
import { Navbar as BsNavbar, NavItem, Nav, Container } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { dAppName, CYBER_TOKEN_ID, GATEWAY } from 'config';
import { routeNames } from 'routes';
import { ReactComponent as CPCLogo } from 'assets/img/cyberpunk-city-logo.svg';
import { ReactComponent as Lightning } from 'assets/img/lightning.svg';
import axios from 'axios';

import './index.scss';

export const Navbar = () => {
  const navigate = useNavigate();
  const { address } = useGetAccountInfo();

  const handleLogout = () => {
    logout(`${window.location.origin}/unlock`);
  };
  const handleLogin = () => {
    navigate(routeNames.unlock, { replace: true });
  };

  const isLoggedIn = Boolean(address);

  const [cyberBalance, setCyberBalance] = useState(0);

  useEffect(() => {
    if (isLoggedIn) {
      (async () => {
        try {
          const url = GATEWAY + '/accounts/' + address + '/tokens/' + CYBER_TOKEN_ID;
          const response: any = await axios.get(url);
          if (response.status == 200) {
            const token = response.data;
            const balance = token['balance'] / Math.pow(10, token['decimals']);
            setCyberBalance(balance);
          }
        } catch (error) {
          console.log(error);
          setCyberBalance(0);
        }
      })();
    }
  }, [isLoggedIn]);

  return (
    <BsNavbar
      collapseOnSelect
      className='navbar-cpc'
      expand='md'
      variant='light'
    >
      <Container className='custom-navbar-container' fluid>
        <BsNavbar.Brand>
          <Link
            className='d-flex align-items-center navbar-brand mr-0'
            to={routeNames.mint}
          >
            <CPCLogo className='cpc-logo' />
          </Link>
        </BsNavbar.Brand>
        <BsNavbar.Toggle aria-controls='responsive-navbar-nav' />
        <BsNavbar.Collapse id='responsive-navbar-nav' className='nav-menu-wrap'>
          <Nav id='responsive-navbar-nav' className='ml-auto'>
            {/* <Link
              className='navbar-links'
              to={routeNames.mint}
              aria-current='page'
            >
              Mint
            </Link> */}
          </Nav>
          {isLoggedIn ? (
            <>
                <div className='text-monospace text-warning float-right'>{cyberBalance.toLocaleString(undefined, { maximumFractionDigits: 0 })} $CYBER <a href={'https://explorer.elrond.com/accounts/' + address} target='blank'> {address.slice(0, 4)}..{address.slice(address.length - 3)}</a></div>
                <br />
              <button
                className='is-style-cpc-btn-styled connect'
                onClick={handleLogout}
              >
                <Lightning className='mr-1' />
                DISCONNECT
              </button>
            </>
          ) : (
            <button
              className='is-style-cpc-btn-styled connect'
              onClick={handleLogin}
            >
              <Lightning className='mr-1' />
              CONNECT
            </button>
          )}
        </BsNavbar.Collapse>
      </Container>
    </BsNavbar>
  );
};
